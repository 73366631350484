<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <content-header :titleList="titleList" />
      <div class="from-content">
        <detail-form/>
      </div>
    </div>
  </div>
</template>

<script>
import DetailForm from './components/DetailForm.vue'
import ContentHeader from "@/components/Common/ContentHeader.vue";
export default {
  components: { DetailForm, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "招生简章",
          isCur: false,
          link: ""
        },
        {
          text: "添加",
          isCur: true,
          link: ""
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.g-section {
  background: #f5f5f5;
}
</style>